import {experimentalStyled as styled} from "@mui/material/styles";
import React, {FunctionComponent} from "react";
import {useGetLenderDetailsQuery} from "apis/requests/lender/getLenderDetails";
import {BounceAddressDetails} from "constants/defaultValues";
import {map} from "lodash";
import FormValidations from "../../helpers/FormValidations";
import {Colors, Container, Text} from "bounce-ui/consumer";
import {Divider} from "@mui/material";
import {LinkHref} from "../../bounce-ui/consumer";
import {useUtils} from "../../contexts";

const getLink = (pageName: string) => {
  return `https://www.finbounce.com/${pageName}`;
};

const NumberList = styled("div")(({theme}) => ({
  display: "flex",
  flexDirection: "row",
}));

const PhoneNumber = styled("a")(({theme}) => ({
  color: theme.palette.text.primary,
  textDecoration: "underline",
}));

const AccountFooter: FunctionComponent = () => {
  const {uiSettings, isMobile} = useUtils();
  const lenderDetails = useGetLenderDetailsQuery(
    {lender_name: uiSettings?.lender_id || ""},
    {enabled: !!uiSettings?.lender_id}
  );
  const phoneNumbers = lenderDetails.data?.phone_numbers || BounceAddressDetails.phone_numbers;

  return (
    <Container sx={{padding: "20px", backgroundColor: Colors.tertiary._200}}>
      <Container
        sx={{
          gap: "20px",
          alignItems: "start",
          bottom: 0,
          ...(!isMobile && {width: "730px"}),
        }}
      >
        <Text variant={"body3"} size={14} sx={{display: "inline"}}>
          Bounce AI, Inc. is a debt collector{" "}
          <Text size={14} sx={{display: "inline"}}>
            attempting to collect a debt. Any information obtained will be used for that purpose.
          </Text>
        </Text>
        <Container
          fullWidth
          sx={{
            gap: "2px",
            alignItems: "start",
          }}
        >
          <Text size={14}>© 2024 Bounce AI All rights reserved.</Text>
          <Text
            size={14}
          >{`${BounceAddressDetails.street_line_1}, ${BounceAddressDetails.street_line_2}, ${BounceAddressDetails.city}, ${BounceAddressDetails.state} ${BounceAddressDetails.zipcode}`}</Text>
          <NumberList>
            {map(phoneNumbers, (phone, index) => {
              return (
                <Text size={14} key={index}>
                  Call us at: &nbsp;
                  <PhoneNumber href={`tel:${phone}`}>
                    {FormValidations.convertInternationalIntoUSFormat(phone)}
                  </PhoneNumber>{" "}
                  &nbsp;
                </Text>
              );
            })}
          </NumberList>
        </Container>
        <Divider sx={{height: "1px", width: "100%", borderColor: Colors.grey._300}} />
        <Container
          fullWidth
          row={!isMobile}
          sx={{
            gap: isMobile ? "4px" : "10px",
            alignItems: "start",
          }}
        >
          <LinkHref text={"Terms and Conditions"} size={14} href={getLink("terms-and-conditions")} />
          <LinkHref text={"State Notices"} size={14} href={getLink("state-notices")} />
          <LinkHref text={"Privacy Policy"} size={14} href={getLink("privacy-policy")} />
          <LinkHref text={"SMS Terms & Conditions"} size={14} href={getLink("sms-terms-conditions")} />
        </Container>
      </Container>
      <div style={{height: "88px"}} />
    </Container>
  );
};

export default AccountFooter;
