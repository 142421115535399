import {Box} from "@twilio-paste/core/box";
import {useSelector} from "react-redux";
import {AppState} from "../store/definitions";
import {Tooltip} from "@mui/material";
import {containerStyles} from "./styles/EntryPoint.styles";
import {useActivityEvent} from "../../../contexts/ActivityEventContext";
import React from "react";
import {ChatTooltipLabel} from "./ChatTooltipLabel";
import {useAmplitude} from "../../../contexts";
import {Pages} from "../../../bi-events/enum";
import {ChatPlansTooltipLabel} from "./ChatPlansTooltipLabel";
import {Colors} from "../../../bounce-ui/colors";
import {useWebchat} from "./useWebchat";
import {IconWebchat, IconWebchatClose} from "../../../bounce-ui/assets";

export const EntryPoint = () => {
  const {
    isUserInactive,
    stopUserInactivityTooltip,
    onStopUserInactivityTooltip,
    isPlansTooltipActive,
    setIsPlansTooltipActive,
    enableHardshipPlanTooltip,
    setEnableHardshipPlanTooltip,
  } = useActivityEvent();
  const {pageClickEvent} = useAmplitude();
  const {openChat} = useWebchat();
  let expanded = useSelector((state: AppState) => state.session.expanded);

  const handleChatNow = () => {
    pageClickEvent({page: Pages.CHAT_BANNER, cta: "chat_now"});
    handleWebchatClick();
  };
  const handleWebchatClick = () => {
    pageClickEvent({page: Pages.CHAT, cta: expanded ? "chat_close" : "chat_open"});
    openChat({});
    onStopUserInactivityTooltip();
  };

  const handleClosePlansTooltip = () => {
    setIsPlansTooltipActive(false);
    setEnableHardshipPlanTooltip(false);
  };

  const getTitle = () => {
    if (isPlansTooltipActive && enableHardshipPlanTooltip) {
      return <ChatPlansTooltipLabel onClose={handleClosePlansTooltip} />;
    } else if (isUserInactive && !stopUserInactivityTooltip) {
      return <ChatTooltipLabel handleClick={handleChatNow} onClose={onStopUserInactivityTooltip} />;
    }
    return undefined;
  };

  return (
    <Tooltip
      open={isPlansTooltipActive || (isUserInactive && !stopUserInactivityTooltip)}
      title={getTitle()}
      placement={isPlansTooltipActive ? "left" : "top"}
      PopperProps={{
        style: {zIndex: 1000},
      }}
      slotProps={{
        ...(isPlansTooltipActive && {
          tooltip: {
            sx: {
              backgroundColor: Colors.primary._800,
            },
          },
          arrow: {
            sx: {
              color: Colors.primary._800,
            },
          },
        }),
      }}
    >
      <Box
        as="button"
        onClick={handleWebchatClick}
        {...containerStyles}
        style={{
          backgroundColor: "#03542C",
        }}
      >
        {expanded ? <IconWebchatClose /> : <IconWebchat />}
      </Box>
    </Tooltip>
  );
};
