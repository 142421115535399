import {Container} from "../Container";
import {Box, Step, StepLabel} from "@mui/material";
import React from "react";
import {SxProps} from "@mui/system";
import {Colors, IconBackArrows} from "../index";
import {StepperStyled} from "./style";
export * from "./useStepper";

export interface IStepperProps {
  numberOfSteps: number;
  activeStep: number;
  handleBack: () => void;
  startOnFirstStep?: boolean;
  className?: string;
  width?: string;
  sx?: SxProps;
}

const UncompletedStep = () => {
  return (
    <Box
      sx={{
        width: "12px",
        height: "12px",
        borderRadius: "50%",
        backgroundColor: Colors.grey._200,
      }}
    />
  );
};

export const Stepper = ({
  numberOfSteps,
  activeStep,
  handleBack,
  width = "135px",
  className = "Stepper",
  sx,
}: IStepperProps) => {
  return (
    <Container row className={className} sx={{justifyContent: "center", gap: "28px", ...sx}}>
      <img src={IconBackArrows} alt="back" onClick={handleBack} style={{cursor: "pointer"}} />
      <StepperStyled sx={{width: width}}>
        {Array.from({length: numberOfSteps}).map((_, index) => (
          <Step key={`step-${index + 1}`}>
            {activeStep >= index ? <StepLabel className={"completedLabel"} /> : <UncompletedStep />}
          </Step>
        ))}
      </StepperStyled>
      <img src={IconBackArrows} alt={""} style={{opacity: 0}} />
    </Container>
  );
};
