import React from "react";
import {Colors, IconCopy, Container, Link, Text} from "bounce-ui/consumer";
import {formatCurrency} from "../../../../helpers/formatNumber";
import {useUserMetaData} from "../../../../contexts";

interface IGuestPaymentLink {
  url: string;
  handleCopyLink: () => void;
  totalBalance: number;
}

const ContributionLink = ({handleCopyLink, url, totalBalance}: IGuestPaymentLink) => {
  const {isAgent} = useUserMetaData();
  if (!url) {
    return null;
  }
  return (
    <>
      <Text size={14}>You can share the following contribution link to let loved ones help with your debt.</Text>
      <Link onClick={handleCopyLink} sx={{cursor: "pointer", width: "100%"}}>
        <Container
          row
          sx={{
            border: `solid 1px ${Colors.grey._300}`,
            borderRadius: "5px",
            padding: "2px 12px",
            justifyContent: "space-between",
          }}
        >
          <Text
            size={16}
            sx={{
              textOverflow: "ellipsis",
              width: isAgent ? "400px" : "300px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              color: Colors.grey._500,
            }}
          >
            {url}
          </Text>
          <IconCopy style={{width: "20px", height: "20px"}} />
        </Container>
      </Link>
      <Text
        size={14}
      >{`They can securely contribute any amount. To pay off the full balance, have them enter ${formatCurrency(
        totalBalance
      )}`}</Text>
    </>
  );
};

export default ContributionLink;
