import React, {FunctionComponent, useCallback, useMemo, useState} from "react";
import TabPanel, {TabInfo} from "../../../../components/layouts/TabPanel";
import DebitPaymentForm from "./DebitPaymentForm";
import AchPaymentForm from "./AchPaymentForm";
import {PaymentDetails} from "../../../../declerations/server";
import {PaymentType} from "../../../../declerations/enums";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {useTask, useUser} from "../../../../contexts";
import {useGetUserWithSelectedAccountQuery} from "../../../../apis/requests/backoffice";
import {useGetUserQuery} from "../../../../apis/requests/user/getUser";
import LoadingCenter from "components/common/LoadingCenter";
import PopularBadge from "./PopularBadge";
import NiceModal from "@ebay/nice-modal-react";
import BlockedACHDialog from "./BlockedACHDialog";
import {get} from "lodash";
import PaymentTabsTest from "./payment-form-test/PaymentTabsTest";
import {getUserFullName} from "../../../backoffice/information-panel/utils";
import {Container, ImgBillingTree, ImgPci} from "../../../../bounce-ui/consumer";

interface PaymentTabPanelProps {
  onCardSubmit: (paymentType: PaymentType, paymentDetails: PaymentDetails, isChangePaymentMethod: boolean) => void;
  onAchSubmit: (paymentType: PaymentType, paymentDetails: PaymentDetails, isChangePaymentMethod: boolean) => void;
  setIsSupervisorView?: (i: boolean) => void;
  closePaymentDialogHandler?: () => void;
  submitLabelId?: string;
  termsAndConditionsId?: string;
  termsAndConditionsTextId?: string;
  isDashboardDialog?: boolean;
  sx?: SxProps<Theme>;
}

export const PaymentsImages = () => (
  <Container fullWidth row sx={{justifyContent: "center", gap: "25px", padding: "10px"}}>
    <ImgBillingTree />
    <ImgPci />
  </Container>
);

const PaymentTabPanel: FunctionComponent<PaymentTabPanelProps> = ({
  onCardSubmit,
  onAchSubmit,
  submitLabelId,
  termsAndConditionsId,
  termsAndConditionsTextId,
  isDashboardDialog = false,
  setIsSupervisorView,
  closePaymentDialogHandler,
}: PaymentTabPanelProps) => {
  const {getBoUserId} = useTask();
  const userId = getBoUserId();
  const {isAgent, featureFlags} = useUser();
  const {data: user} = useGetUserQuery({});
  const {data: userDetails} = useGetUserWithSelectedAccountQuery({userId}, {enabled: !!userId});

  const featureFlagPaymentForm = useMemo(() => get(featureFlags, "PAYMENT_FORM") ?? false, [featureFlags]);
  const [paymentMethod, setPaymentMethod] = useState<PaymentType>(user?.ach_allowed ? "ACH" : "DebitCard");
  const achLabelId = useMemo((): string => {
    const labelId = "ach";
    const labelIdRequiresApproval = labelId + "_requires_approval";
    if (isAgent && userDetails) {
      if (!userDetails?.ach_allowed) return labelIdRequiresApproval;
      return labelId;
    }
    return labelId;
  }, [user, isAgent, userDetails]);

  const handleAchClick = useCallback(
    (setDebitActive: (id: string) => void) => {
      const isAchBlocked = isAgent ? userDetails && !userDetails.ach_allowed : user && !user.ach_allowed;
      if (isAchBlocked) {
        setDebitActive("debitCard");
        NiceModal.show(BlockedACHDialog, {
          setAchActiveTab: () => setDebitActive("ach"),
          secondaryButtonText: isAgent ? "approved_by_supervisor" : "contact_us",
          isAgent,
          setIsSupervisorView,
          closePaymentDialogHandler,
        });
      }
    },
    [user, userDetails]
  );

  const tabs = useMemo<TabInfo[]>(
    () => [
      {
        id: "ach",
        label: achLabelId,
        icon: isAgent || !user?.ach_allowed ? undefined : PopularBadge,
        onClick: handleAchClick,
      },
      {id: "debitCard", label: "debit"},
    ],
    [user, achLabelId]
  );

  const primaryEmail: string = useMemo(() => {
    if (isAgent && userDetails?.representation) {
      return userDetails?.representation.email || "";
    } else if (user?.represented) {
      return user.representation_email || "";
    }
    return user?.email || userDetails?.email || "";
  }, [userDetails, user]);

  if (userId && !userDetails) {
    return <LoadingCenter />;
  }

  return (
    <Container fullWidth>
      {featureFlagPaymentForm ? (
        <PaymentTabsTest
          onCardSubmit={onCardSubmit}
          onAchSubmit={onAchSubmit}
          submitLabelId={submitLabelId}
          termsAndConditionsId={termsAndConditionsId}
          termsAndConditionsTextId={termsAndConditionsTextId}
          isDashboardDialog={isDashboardDialog}
          primaryEmail={primaryEmail}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          userName={isAgent && userDetails ? getUserFullName(userDetails) : user?.name}
        />
      ) : (
        <TabPanel
          tabs={tabs}
          addToHistory={false}
          defaultTabId={user?.ach_allowed ? "ach" : "debitCard"}
          saveActiveTab={false}
          onDashboardDialog={isDashboardDialog}
          iconPosition={"end"}
        >
          <AchPaymentForm
            onAchSubmit={onAchSubmit}
            submitLabelId={submitLabelId}
            termsAndConditionsId={termsAndConditionsId}
            termsAndConditionsTextId={termsAndConditionsTextId}
            isChangePaymentMethod={isDashboardDialog}
            primaryEmail={primaryEmail}
          />
          <DebitPaymentForm
            type={"DebitCard"}
            onCardSubmit={onCardSubmit}
            submitLabelId={submitLabelId}
            termsAndConditionsId={termsAndConditionsId}
            termsAndConditionsTextId={termsAndConditionsTextId}
            isChangePaymentMethod={isDashboardDialog}
            primaryEmail={primaryEmail}
          />
        </TabPanel>
      )}
    </Container>
  );
};
export default PaymentTabPanel;
