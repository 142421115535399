import React, {ReactNode} from "react";
import {Alert} from "@mui/material";
import {SxProps} from "@mui/system";
import useIsMobile from "../../helpers/useIsMobile";

export interface IPaymentTipAlert {
  onClose: () => void;
  children?: ReactNode;
  sx?: SxProps;
}
export const PopUpAlert = ({onClose, children, sx}: IPaymentTipAlert) => {
  const isMobile = useIsMobile();

  return (
    <Alert
      icon={false}
      variant={"outlined"}
      severity={"info"}
      onClose={onClose}
      sx={{
        backgroundColor: "white",
        borderColor: "white",
        boxShadow: (theme) => theme.customShadows.cardV2,
        position: "absolute",
        padding: "0px 10px",
        alignItems: "center",
        width: isMobile ? "350px" : "550px",
        ".MuiAlert-message": {
          width: "100%",
          padding: "0px",
        },
        borderRadius: "8px",
        ...sx,
      }}
    >
      {children}
    </Alert>
  );
};
