import React, {FunctionComponent, useEffect, useMemo, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {Autocomplete} from "@mui/material";
import NiceModal, {NiceModalHocProps, useModal} from "@ebay/nice-modal-react";
import {map, isEmpty} from "lodash";
import {getUserFilesOptions} from "../../utils";
import {useSendArbitraryContent} from "../../../../../apis/requests/backoffice";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {DesktopDateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {ISO_FORMAT} from "./common";
import {FileRead} from "../../../../../declerations/server/files_models";
import {getUserFiles} from "apis/requests/files";
import {ExcludedFileTypes} from "../../tabs/documents/utils";
import {Text, Container, TextField, FormControlLabel, Radio, RadioGroup, Dialog, Checkbox} from "bounce-ui/backoffice";
import {ICommunicationForms, IEmailForm} from "./useCommunicationForms";
import {getEmailDefaultValues, openConfirmCommunicationDialog} from "./utils";
import {useGetPoliceRules} from "./useGetPoliceRules";

dayjs.extend(utc);
dayjs.extend(timezone);

export type SendEmailDialogProps = {
  userEmails: string[];
  userId: string;
  accountId: string;
  defaultEmail: string;
  subject?: string;
  shouldIncludeBody?: boolean;
  emailFileId?: string;
  shouldDisplayAttachmentWarning?: boolean;
  setEmail: (form: IEmailForm) => void;
  communicationForms: ICommunicationForms;
};

type FormValues = {
  email: string;
  date: Dayjs;
  subject: string;
  message?: string;
  files: Array<string>;
};

const SendEmailDialog: FunctionComponent<SendEmailDialogProps & NiceModalHocProps> = NiceModal.create(
  ({
    userEmails,
    userId,
    accountId,
    defaultEmail,
    subject,
    shouldIncludeBody = true,
    shouldDisplayAttachmentWarning = false,
    emailFileId,
    setEmail,
    communicationForms,
  }) => {
    const modal = useModal();
    const sendArbitraryContent = useSendArbitraryContent({refetchType: "all"});
    const [userFiles, setUserFiles] = useState<FileRead[]>([]);
    const defaultValues = getEmailDefaultValues(communicationForms?.email, defaultEmail, subject || "");
    const {control, handleSubmit, watch, getValues} = useForm<FormValues>({
      defaultValues,
    });
    const {policeBrokenRules} = useGetPoliceRules({
      userId,
      contact: getValues("email"),
      body: getValues("message") || "fake_content",
      date: getValues("date"),
      communicationType: "email",
    });
    const userFilesOptions = useMemo(() => {
      if (userFiles) {
        return getUserFilesOptions(userFiles);
      }
    }, [userFiles]);
    const defaultFiles = useMemo(() => {
      if (communicationForms && userFiles && defaultValues) {
        return getUserFilesOptions(userFiles.filter((file) => defaultValues.files.includes(file.id)));
      }
    }, [userFiles, defaultValues]);
    const currSubject = watch("subject");
    const currDate = watch("date");

    const onSubmit = handleSubmit((data): void => {
      const dateInUtcStr = dayjs(data.date).utc(false).format(ISO_FORMAT);

      sendArbitraryContent.mutateAsync({
        user_id: userId,
        account_id: accountId,
        content: {
          subject: data.subject,
          body: data.message,
          attachments: data.files,
          communication_type: "email",
          html_file_id: emailFileId,
        },
        contact: data.email,
        date: dateInUtcStr,
      });
      modal.resolve(true);
      modal.hide();
    });

    const onClose = (): void => {
      const data = getValues();
      if (communicationForms) {
        setEmail({
          email: data.email,
          date: data.date,
          message: data.message,
          subject: data.subject,
          files: data.files,
        });
      }
      modal.hide();
    };

    useEffect(() => {
      const getUserFilesWrapper = async () => {
        const files = await getUserFiles({userId, accountId, isAgent: true, excludeFiles: ExcludedFileTypes});
        setUserFiles(files);
      };
      getUserFilesWrapper();
    }, []);

    return (
      <Dialog
        title={"Send an email"}
        onClick={() => openConfirmCommunicationDialog(policeBrokenRules, onSubmit)}
        buttonText={"Send"}
        onClose={onClose}
      >
        <form noValidate style={{width: "100%"}}>
          <Container sx={{gap: "16px", alignItems: "start"}}>
            <Controller
              name="email"
              rules={{
                required: "Required field",
              }}
              control={control}
              render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                return (
                  <RadioGroup {...field} name="radio-buttons-emails">
                    {map(userEmails, (email) => {
                      return (
                        <FormControlLabel
                          key={email}
                          value={email}
                          control={<Radio sx={{paddingRight: "4px"}} />}
                          label={email}
                        />
                      );
                    })}
                  </RadioGroup>
                );
              }}
            />
            <Controller
              name="date"
              control={control}
              render={({field: {ref, onChange, ...field}, fieldState: {invalid, error}}) => {
                return (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDateTimePicker
                      value={dayjs(currDate).tz("America/New_York")}
                      onChange={onChange}
                      minDate={dayjs().tz("America/New_York")}
                      label="Eastern Time"
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "8px",
                        },
                      }}
                    />
                  </LocalizationProvider>
                );
              }}
            />
            <Controller
              name="subject"
              rules={{
                required: "Required field",
              }}
              control={control}
              render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                return (
                  <TextField
                    {...field}
                    inputRef={ref}
                    required
                    fullWidth
                    value={currSubject || ""}
                    placeholder="Subject"
                    autoComplete="off"
                    autoFocus
                    error={invalid}
                    helperText={error?.message}
                  />
                );
              }}
            />
            {shouldIncludeBody && (
              <Controller
                name="message"
                rules={{
                  required: shouldIncludeBody ? "Required field" : false,
                }}
                control={control}
                render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                  return (
                    <TextField
                      {...field}
                      inputRef={ref}
                      rows={7}
                      required
                      fullWidth
                      multiline
                      placeholder="Enter Your Message"
                      autoComplete="off"
                      autoFocus
                      error={invalid}
                      helperText={error?.message}
                    />
                  );
                }}
              />
            )}
            {userFilesOptions && !isEmpty(userFilesOptions) && defaultFiles && (
              <Controller
                name="files"
                control={control}
                render={({field: {ref, ...field}}) => {
                  return (
                    <Autocomplete
                      multiple
                      id="attach-existing-files"
                      options={userFilesOptions}
                      disableCloseOnSelect
                      isOptionEqualToValue={(value, option) => option?.value === value?.value}
                      getOptionLabel={(option) => option.label}
                      onChange={(_, value) => {
                        field.onChange(map(value, (v) => v?.value) || []);
                      }}
                      fullWidth
                      defaultValue={defaultFiles}
                      renderOption={(props, option, {selected}) => (
                        <li {...props} key={option.value}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{marginRight: 8}}
                            checked={selected}
                          />
                          <Text sx={{overflow: "auto"}}>{option.label}</Text>
                        </li>
                      )}
                      renderInput={(params) => <TextField {...params} label="Attach files" sx={{maxWidth: "300px"}} />}
                    />
                  );
                }}
              />
            )}
            {shouldDisplayAttachmentWarning && (
              <Text color={"red"}>
                Please note that the original email has attachments - please attach them manually if needed
              </Text>
            )}
          </Container>
        </form>
      </Dialog>
    );
  }
);

export default SendEmailDialog;
