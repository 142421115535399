import {useLocation, useNavigate} from "react-router-dom";
import {useAmplitude} from "./AmplitudeContext";
import {useUser} from "./UserContext";
import {useSnackbar} from "notistack";
import {useUi} from "./UiContext";
import useIsMobile from "../helpers/useIsMobile";
import {useTab} from "./useTab";
import {useTask} from "./TaskContext";

export const useUtils = () => {
  const {clearCredentials, featureFlags, setCredentials} = useUser();
  const {uiSettings, fetchAnimationJson, animatedConfettiJson} = useUi();
  const {pageClickEvent, pageViewEvent, setError} = useAmplitude();
  const navigate = useNavigate();
  const {state, search, pathname} = useLocation();
  const {enqueueSnackbar} = useSnackbar();
  const isMobile = useIsMobile();
  const currentTab = useTab();
  const {navigateToSearchPanel, clearUserId} = useTask();

  return {
    pageClickEvent,
    pageViewEvent,
    setError,
    enqueueSnackbar,
    clearCredentials,
    fetchAnimationJson,
    animatedConfettiJson,
    setCredentials,
    navigateToSearchPanel,
    navigate,
    state,
    featureFlags,
    uiSettings,
    isMobile,
    currentTab,
    search,
    pathname,
    clearUserId,
  };
};
