import React, {FunctionComponent, PropsWithChildren, useContext, useState} from "react";
import {isSpring} from "../layout/spring/helpers";
import {urls} from "../routes/urls";
import {useNavigate} from "react-router-dom";

export interface TaskContextProps {
  userId: string;
  setUserId: (id: string) => void;
  getBoUserId: () => string;
  clearUserId: () => void;
  navigateToSearchPanel: () => void;
}

const TaskContext = React.createContext<TaskContextProps>(undefined!);

interface IProps extends PropsWithChildren<any> {}

const TaskProvider: FunctionComponent<IProps> = ({children}) => {
  const [userId, setUserIdInternal] = useState("");
  const navigate = useNavigate();

  const setUserId = (id: string) => {
    sessionStorage.setItem("userId", id);
    setUserIdInternal(id);
  };

  const getBoUserId = () => {
    const id = sessionStorage.getItem("userId") || userId;
    return String(id);
  };

  const navigateToSearchPanel = () => {
    if (isSpring()) {
      navigate(urls.spring.url);
    } else {
      navigate(urls.backoffice.url);
    }
  };

  // TODO: need to understand when to clear the session storage (while the agent mark a task as completed?)
  const clearUserId = () => {
    sessionStorage.removeItem("userId");
  };

  return (
    <TaskContext.Provider
      value={{
        userId,
        setUserId,
        getBoUserId,
        clearUserId,
        navigateToSearchPanel,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};

const useTask = (): TaskContextProps => {
  const context = useContext(TaskContext);

  if (!context) throw new Error("TaskContext must be used inside user TaskProvider");

  return context;
};

export {TaskContext, TaskProvider, useTask};
