import {useAccountId} from "./useAccountId";
import {useUser} from "./UserContext";
import {useTask} from "./TaskContext";

export const useUserMetaData = () => {
  const {isAgent, isMultiAccount, loggedIn, token, agentEmail, agentRole, userId, isAdmin} = useUser();
  const {getBoUserId} = useTask();
  const accountId = useAccountId();

  return {
    accountId,
    loggedIn,
    token,
    agentEmail,
    agentRole,
    isAgent,
    userId,
    isMultiAccount,
    isAdmin,
    getBoUserId,
  };
};
