import {ReactNode, useEffect, useMemo} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {useUser} from "../../contexts";
import {urls} from "../urls";

type GuestGuardProps = {
  unknownUser?: boolean;
  children: ReactNode;
};

interface LocationState {
  origin?: string;
}

const GuestGuard = ({unknownUser = false, children}: GuestGuardProps) => {
  const {loggedIn, clearCredentials, isAgent} = useUser();
  const location = useLocation();
  const token = useMemo<string>(() => localStorage.getItem("agentToken") || "", [localStorage]);
  const agentEmail = useMemo<string>(() => localStorage.getItem("agentEmail") || "", [localStorage]);
  const agentRole = useMemo<string>(() => localStorage.getItem("agentRole") || "", [localStorage]);
  useEffect(() => {
    if (token) {
      localStorage.removeItem("agentToken");
      clearCredentials();
    }
    if (agentEmail) {
      localStorage.removeItem("agentEmail");
    }
    if (agentRole) {
      localStorage.removeItem("agentRole");
    }
  }, [token, agentEmail, agentRole]);

  if (loggedIn && !isAgent && !unknownUser) {
    if (location.state) {
      const {origin} = location.state as LocationState;
      if (origin) {
        return <Navigate to={origin} />;
      }
    }
    return <Navigate to={urls.accounts.url} />;
  }

  return <>{children}</>;
};

export default GuestGuard;
