import {Chip as MuiChip, ChipProps} from "@mui/material";
import React, {ReactNode} from "react";
import {Text} from "./Text";
import {Colors} from "../colors";

export type ChipType = ChipProps & {
  size_?: "small" | "medium" | "large";
  label: string;
  variant_?: "secondary" | "inherit" | "primary" | "success" | "error" | "info" | "warning" | "promotion" | undefined;
  border?: boolean;
  onClick?: any;
  icon?: ReactNode;
};

const chipProps = {
  height: {
    small: "22px",
    medium: "26px",
    large: "32px",
  },
  padding: {
    small: "2px 6px",
    medium: "2px 8px",
    large: "4px 8px",
  },
  font: {
    small: "12px",
    medium: "14px",
    large: "14px",
  },
  iconSize: {
    small: "16px",
    medium: "16px",
    large: "20px",
  },
  borderRadius: {
    small: "6px",
    medium: "8px",
    large: "8px",
  },
  color: {
    primary: {text: Colors.bw.white, background: Colors.primary._800, borderColor: Colors.primary._800},
    secondary: {text: Colors.primary._800, background: Colors.primary._100, borderColor: Colors.primary._400},
    inherit: {text: Colors.grey._1000, background: Colors.grey._100, borderColor: Colors.grey._400},
    error: {text: Colors.red._900, background: Colors.red._100, borderColor: Colors.red._400},
    warning: {text: Colors.orange._900, background: Colors.orange._100, borderColor: Colors.orange._400},
    success: {text: Colors.green._900, background: Colors.green._100, borderColor: Colors.green._400},
    info: {text: Colors.secondary._900, background: Colors.secondary._100, borderColor: Colors.secondary._400},
    promotion: {
      text: Colors.bw.white,
      background: Colors.grey._1000,
      borderColor: Colors.grey._1000,
    },
  },
};
export const Chip = ({
  size_ = "small",
  label,
  border = false,
  variant_ = "primary",
  onClick,
  className = "Button",
  icon,
  children,
  sx,
  ...props
}: ChipType) => {
  const {text, borderColor, background} = chipProps.color[variant_];
  return (
    <MuiChip
      className={className}
      onClick={onClick || null}
      icon={icon}
      label={
        <Text variant={"body2"} sx={{color: text, fontSize: chipProps.font[size_]}}>
          {label}
        </Text>
      }
      sx={{
        "& .MuiChip-deleteIcon": {
          fontSize: chipProps.font[size_],
          padding: "0px 0px 0px 10px",
        },
        "& .MuiChip-label": {
          padding: icon ? "0px 0px 0px 10px" : "0px",
        },
        fontWeight: 500,
        borderRadius: chipProps.borderRadius[size_],
        padding: chipProps.padding[size_],
        height: chipProps.height[size_],
        whiteSpace: "nowrap",
        backgroundColor: background,
        ...(border && {border: `1px solid ${borderColor}`}),
        ...sx,
      }}
      {...props}
    />
  );
};
