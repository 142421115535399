// ----------------------------------------------------------------------
import "./fonts/fonts.css";

const PRIMARY_FONT = "Inter, sans-serif";
const SECONDARY_FONT = "InterDisplay, sans-serif";
const TIEMPOS = "TestTiemposText, sans-serif";
const TIEMPOS_HEAD = "TestTiemposHeadline, sans-serif";

const typography = {
  fontFamily: PRIMARY_FONT,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontFamily: SECONDARY_FONT,
    fontWeight: 400,
  },
  h2: {
    fontFamily: SECONDARY_FONT,
    fontWeight: 500,
  },
  h3: {
    fontFamily: SECONDARY_FONT,
    fontWeight: 600,
  },
  body1: {
    fontFamily: PRIMARY_FONT,
    fontWeight: 400,
  },
  body2: {
    fontFamily: PRIMARY_FONT,
    fontWeight: 500,
  },
  body3: {
    fontFamily: PRIMARY_FONT,
    fontWeight: 600,
  },
  tiempos1: {
    fontFamily: TIEMPOS,
    fontWeight: 400,
  },
  tiempos2: {
    fontFamily: TIEMPOS,
    fontWeight: 500,
  },
  tiempos3: {
    fontFamily: TIEMPOS,
    fontWeight: 600,
  },
  tiemposHead1: {
    fontFamily: TIEMPOS_HEAD,
    fontWeight: 400,
  },
  tiemposHead2: {
    fontFamily: TIEMPOS_HEAD,
    fontWeight: 500,
  },
  tiemposHead3: {
    fontFamily: TIEMPOS_HEAD,
    fontWeight: 600,
  },
} as const;

export default typography;
