import {ChannelType, EventType} from "./activity_models";
import {
  Colors,
  IconAccountUpdate,
  IconApp,
  IconCallIn,
  IconCallOut,
  IconChat,
  IconCommentOutlined,
  IconDisputeOutlined,
  IconEmailIn,
  IconEmailOut,
  IconLetter,
  IconPayment,
  IconSmsIn,
  IconSmsOut,
  IconTriggerIn,
} from "bounce-ui/backoffice";
import {PhoneCallItem} from "./PhoneCallItem";
import {SMSItem} from "./SMSItem";
import {EmailItem} from "./EmailItem";
import {LetterItem} from "./LetterItem";
import {WebchatItem} from "./WebchatItem";
import {PaymentItem} from "./PaymentItem";
import {PaymentPlanItem} from "./PaymentPlanItem";
import {AccountUpdateItem} from "./AccountUpdateItem";
import {ContactUsItem} from "./ContactUsItem";
import {UserActivityItem} from "./UserActivityItem";
import InputUtils from "../../../../../../helpers/InputUtils";
import dayjs, {Dayjs} from "dayjs";
import {DATE_FORMAT_V2} from "../../../components/dialogs/common";
import {DisputeItem} from "./DisputeItem";
import {CollectionNoticeTriggerItem} from "./CollectionNoticeTriggerItem";
import {AllPlanFrequencies} from "../../../../../../declerations/enums";
import {get} from "lodash";
import {planTypeToLabelMap} from "./activitiesSubtitle";
import {CommentItem} from "./CommentItem";

interface ChannelDetails {
  color: string;
  channelActivity: any;
}

interface EventDetails {
  icon: any;
  labelKey: string;
}

interface IGetPhoneNumber {
  direction: string;
  to_number: string;
  from_number: string;
}

enum CommunicationDirection {
  OUTBOUND = "OUTBOUND",
  INBOUND = "INBOUND",
}

enum CommunicateBy {
  AGENT = "Agent",
  USER = "User",
  SYSTEM = "System",
}

enum InteractionType {
  EMAIL_SENT = "EMAIL_SENT",
  EMAIL_RECEIVED = "EMAIL_RECEIVED",
  PHONE_CALL_INCOMING_ANSWERED = "PHONE_CALL_INCOMING_ANSWERED",
  PHONE_CALL_INCOMING_NOT_ANSWERED = "PHONE_CALL_INCOMING_NOT_ANSWERED",
  OUTBOUND_PHONE_CALL_ANSWERED = "OUTBOUND_PHONE_CALL_ANSWERED",
  PHONE_CALL_INCOMING_ASSIGNED_TO_RESOLUTION_EXPERT = "PHONE_CALL_INCOMING_ASSIGNED_TO_RESOLUTION_EXPERT",
  OUTBOUND_CALL_DIALED = "OUTBOUND_CALL_DIALED",
  ARBITRARY_CALL_DIALED = "ARBITRARY_CALL_DIALED",
  SMS_INCOMING = "SMS_INCOMING",
  SMS_QUEUED = "SMS_QUEUED",
  SMS_SENT = "SMS_SENT",
  SMS_OPT_OUT = "SMS_OPT_OUT",
  WEBCHAT_COMPLETED = "WEBCHAT_COMPLETED",
  ACCOUNT_UPDATE = "ACCOUNT_UPDATE",
  DISPUTE_FILED = "DISPUTE_FILED",
  PAYMENT_PLAN_SELECTED = "PAYMENT_PLAN_SELECTED",
  PAYMENT_PLAN_DEACTIVATED = "PAYMENT_PLAN_DEACTIVATED",
  PAYMENT_PLAN_FREEZE_PAYMENT = "PAYMENT_PLAN_FREEZE_PAYMENT",
  LETTER_QUEUED = "LETTER_QUEUED",
  CONTACT_US = "CONTACT_US",
  PAYMENT_SUCCESS = "PAYMENT_SUCCESS",
  PAYMENT_FAILURE = "PAYMENT_FAILURE",
  PHONE_CALL_SUMMARY_RECEIVED = "PHONE_CALL_SUMMARY_RECEIVED",
  TIMED_ARBITRARY_COMMUNICATION = "TIMED_ARBITRARY_COMMUNICATION",
  COLLECTION_NOTICE_TRIGGER = "COLLECTION_NOTICE_TRIGGER",
}

const ChannelTypeMap = new Map<ChannelType, ChannelDetails>([
  ["phone_call", {color: Colors.pastel.lightPurple, channelActivity: PhoneCallItem}],
  ["sms", {color: Colors.pastel.pink, channelActivity: SMSItem}],
  ["email", {color: Colors.pastel.orange, channelActivity: EmailItem}],
  ["letter", {color: Colors.pastel.mint, channelActivity: LetterItem}],
  ["webchat", {color: Colors.pastel.green, channelActivity: WebchatItem}],
  ["webchat_offline", {color: Colors.pastel.green, channelActivity: WebchatItem}],
  ["payment_plan", {color: Colors.pastel.coral, channelActivity: PaymentPlanItem}],
  ["payment", {color: Colors.pastel.coral, channelActivity: PaymentItem}],
  ["account_update", {color: Colors.pastel.blue, channelActivity: AccountUpdateItem}],
  ["contact_us", {color: Colors.pastel.blue, channelActivity: ContactUsItem}],
  ["dispute", {color: Colors.pastel.lightCoral, channelActivity: DisputeItem}],
  ["user_activity", {color: Colors.pastel.purple, channelActivity: UserActivityItem}],
  ["trigger", {color: Colors.pastel.purple, channelActivity: CollectionNoticeTriggerItem}],
  ["comment", {color: Colors.pastel.yellow, channelActivity: CommentItem}],
]);
const EventTypeMap = new Map<EventType, EventDetails>([
  ["PHONE_CALL_INCOMING", {labelKey: "Phone Call Incoming", icon: IconCallIn}],
  ["PHONE_CALL_MISSED_INCOMING", {labelKey: "Missed Incoming Call", icon: IconCallIn}],
  ["PHONE_CALL_DIALED", {labelKey: "Outbound Call", icon: IconCallOut}],
  ["SCHEDULED_PHONE_CALL", {labelKey: "Scheduled Phone Call", icon: IconCallOut}],
  ["SMS_RECEIVED", {labelKey: "SMS Received", icon: IconSmsIn}],
  ["SMS_DELIVERED", {labelKey: "SMS Sent", icon: IconSmsOut}],
  ["SMS_UNDELIVERED", {labelKey: "SMS Undelivered", icon: IconSmsOut}],
  ["SMS_LINK_CLICK", {labelKey: "SMS Link Click", icon: IconSmsIn}],
  ["SMS_PAGE_CLICK", {labelKey: "SMS Page Click", icon: IconSmsIn}],
  ["SMS_OPT_OUT", {labelKey: "SMS Opt Out", icon: IconSmsIn}],
  ["SCHEDULED_SMS", {labelKey: "Scheduled SMS", icon: IconSmsOut}],
  ["EMAIL_RECEIVED", {labelKey: "Email Received", icon: IconEmailIn}],
  ["EMAIL_DELIVERED", {labelKey: "Email Sent", icon: IconEmailOut}],
  ["EMAIL_OPEN", {labelKey: "Email Open", icon: IconEmailIn}],
  ["EMAIL_CLICK", {labelKey: "Email Click", icon: IconEmailIn}],
  ["EMAIL_UNSUBSCRIBE", {labelKey: "Email Unsubscribe", icon: IconEmailIn}],
  ["SCHEDULED_EMAIL", {labelKey: "Scheduled Email", icon: IconEmailOut}],
  ["LETTER", {labelKey: "Letter Sent", icon: IconLetter}],
  ["WEBCHAT_CHAT", {labelKey: "Chat", icon: IconChat}],
  ["WEBCHAT_OFFLINE", {labelKey: "Offline Chat", icon: IconChat}],
  ["PAYMENT_PLAN_SELECTED", {labelKey: "Payment Plan Selected", icon: IconPayment}],
  ["PAYMENT_PLAN_DEACTIVATED", {labelKey: "Payment Plan Deactivated", icon: IconPayment}],
  ["PAYMENT_RECEIVED", {labelKey: "Payment Received", icon: IconPayment}],
  ["PAYMENT_FAILED", {labelKey: "Payment Failed", icon: IconPayment}],
  ["PAYMENT_SUBMITTED", {labelKey: "Payment Received", icon: IconPayment}],
  ["PAYMENT_FAILED_RETRY", {labelKey: "Payment Failed (Retry)", icon: IconPayment}],
  ["PAYMENT_PENDING", {labelKey: "Payment Pending", icon: IconPayment}],
  ["ACCOUNT_UPDATE_ADD", {labelKey: "Account Update", icon: IconAccountUpdate}],
  ["ACCOUNT_UPDATE_DELETE", {labelKey: "Account Update", icon: IconAccountUpdate}],
  ["ACCOUNT_UPDATE", {labelKey: "Account Update", icon: IconAccountUpdate}],
  ["CONTACT_US", {labelKey: "Contact Us", icon: IconEmailIn}],
  ["DISPUTE_FILED", {labelKey: "Dispute filed", icon: IconDisputeOutlined}],
  ["USER_POSTPONED_CREDIT_REPORT", {labelKey: "App visit", icon: IconApp}],
  ["USER_VISIT_PAYMENT_PLAN_SUMMARY", {labelKey: "App visit", icon: IconApp}],
  ["USER_VISIT_PAYMENT_PLANS", {labelKey: "App visit", icon: IconApp}],
  ["LOG_IN", {labelKey: "App visit", icon: IconApp}],
  ["VALIDATION_IN_APP", {labelKey: "App visit", icon: IconApp}],
  ["COLLECTION_NOTICE_TRIGGER", {labelKey: "External Data Received", icon: IconTriggerIn}],
  ["UNKNOWN", {labelKey: "Unknown", icon: IconAccountUpdate}],
  ["INSTALLMENT_DUE_DATE_UPDATED", {labelKey: "Payment Rescheduled", icon: IconPayment}],
  ["COMMENT", {labelKey: "Comment", icon: IconCommentOutlined}],
]);

const getNameFromEmail = (input: string) => {
  const parts = input.split("@");
  return parts[0];
};

const getPrincipalLabel = (principal: string) => {
  if (principal) {
    if (principal.includes("@")) return principal;
    if (InputUtils.containsOnlyDigits(principal)) return CommunicateBy.USER;
    return principal;
  }
  return CommunicateBy.SYSTEM;
};

const getSuffixStatusLabel = (prefix: string, status: string) => {
  if (status && status.startsWith(prefix)) {
    return InputUtils.prettierSnakeCase(status.slice(prefix.length));
  }
  return InputUtils.prettierSnakeCase(status);
};

const CallContactColorsMap = (): Map<string, {color: string}> => {
  return new Map<string, {color: string}>([
    ["right_party_contact", {color: Colors.green._700}],
    ["third_party_contact", {color: Colors.green._700}],
    ["voicemail", {color: Colors.orange._600}],
    ["no_answer", {color: Colors.red._700}],
    ["unverified_contact", {color: Colors.red._700}],
    ["disconnected_number", {color: Colors.red._700}],
    ["wrong_number", {color: Colors.red._700}],
  ]);
};

const getDateLabel = (date: Dayjs): string => {
  const today = dayjs().tz("America/New_York");
  if (date.isAfter(today, "day")) return "";
  if (date.isSame(today, "day")) return "Today";
  if (date.isSame(today.subtract(1, "day"), "day")) return "Yesterday";
  if (date.isAfter(today.subtract(5, "day")) && date.isBefore(today.subtract(1, "day"))) {
    return `Last ${date.format("dddd")}`;
  }
  return date.format(DATE_FORMAT_V2);
};

const getPhoneNumber = ({direction, to_number, from_number}: IGetPhoneNumber) => {
  if (direction === CommunicationDirection.OUTBOUND) {
    if (to_number) {
      return `To: ${to_number}`;
    }
  }
  if (from_number) {
    return `From: ${from_number}`;
  }
};

const getPaymentPlanSubtitleByType = ({
  isInterimPlan,
  isHardship,
  isPayoffPlan,
  frequency,
}: {
  isInterimPlan: boolean;
  isHardship: boolean;
  isPayoffPlan: boolean;
  frequency: AllPlanFrequencies;
}) => {
  if (isInterimPlan) {
    return "Temp relief program";
  }
  if (isHardship) {
    return "Hardship plan";
  }
  if (isPayoffPlan) {
    return "Early payoff";
  }
  return get(planTypeToLabelMap.get(frequency), "labelKey", "");
};

export {
  InteractionType,
  CommunicationDirection,
  CommunicateBy,
  ChannelTypeMap,
  EventTypeMap,
  getNameFromEmail,
  getPrincipalLabel,
  getSuffixStatusLabel,
  CallContactColorsMap,
  getDateLabel,
  getPhoneNumber,
  getPaymentPlanSubtitleByType,
};
