import React, {FunctionComponent, ReactNode} from "react";
import {Dialog as MuiDialog} from "@mui/material";
import {useModal} from "@ebay/nice-modal-react";
import InputUtils from "../../../helpers/InputUtils";
import {CloseDialogButton} from "../CloseDialogButton";
import {Container} from "../Container";
import {Button} from "../Button";
import {Text} from "../Text";
import useIsMobile from "../../../helpers/useIsMobile";

export type DialogType = {
  title: string;
  image?: ReactNode;
  onClick?: Function;
  onClose?: () => void;
  buttonText?: string;
  disabled?: boolean;
  autoClose?: boolean;
  width?: string;
  secondaryButtonText?: string;
  onClickSecondary?: () => void;
  isError?: boolean;
  children?: ReactNode;
};

export const Dialog: FunctionComponent<DialogType> = ({
  title,
  buttonText = "Confirm",
  image,
  onClick,
  onClose,
  secondaryButtonText,
  onClickSecondary,
  isError,
  disabled = false,
  width,
  autoClose = true,
  children,
}) => {
  const modal = useModal();
  const isMobile = useIsMobile();

  const onClickModal = async () => {
    onClick?.();
    if (autoClose) {
      modal.resolve(true);
      modal.hide();
    }
  };

  const onClickSecondaryModal = async () => {
    onClickSecondary?.();
    modal.hide();
  };

  return (
    <MuiDialog
      open={modal.visible}
      TransitionProps={{
        onExited: () => {
          modal.remove();
          onClose?.();
        },
      }}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "16px",
          padding: isMobile ? "44px 24px 24px 24px" : "44px",
          "&.MuiPaper-elevation": {
            margin: "12px",
          },
        },
      }}
    >
      <CloseDialogButton onClose={modal.hide} />
      <Container sx={{gap: "24px", width: width || "300px"}}>
        {image}
        <Container fullWidth sx={{alignItems: "start", whiteSpace: "pre-line"}}>
          <Text variant={"h3"} size={24}>
            {title}
          </Text>
        </Container>
        {children}
        <Container fullWidth sx={{gap: "8px"}}>
          <Button
            buttonType={isError ? "contained-error" : "contained-primary"}
            onClick={onClickModal}
            disabled={disabled}
            fullWidth
          >
            {buttonText}
          </Button>
          {secondaryButtonText && (
            <Button
              buttonType={isError ? "outlined-secondary" : "contained-secondary"}
              onClick={onClickSecondaryModal}
              fullWidth
            >
              {InputUtils.prettierSnakeCaseSentence(secondaryButtonText)}
            </Button>
          )}
        </Container>
      </Container>
    </MuiDialog>
  );
};
