import {axiosConsumerAppInstance, axiosFlexInstance} from "../../ServerApiManager";
import axios from "axios";
import JSZip from "jszip";

type Variables = {
  accountId?: string;
  fileId: string;
  isAgent?: boolean;
  isMobile?: boolean;
  viewMode?: boolean;
  fileName?: string;
  htmlView?: boolean;
};

export type DownloadLink = {
  url: string;
  file_name: string;
};

export const isNotChromeOniPhone = () => {
  const userAgent = navigator.userAgent;
  const isiPhone = /iPhone/i.test(userAgent);
  const isChrome = /CriOS/i.test(userAgent);

  return isiPhone && !isChrome;
};

export const getUserFile = async (variables: Variables): Promise<DownloadLink> => {
  let result;
  if (variables?.isAgent) {
    result = await axiosFlexInstance.get<DownloadLink>(`/files/download-link/${variables.fileId}`, {
      params: {view_mode: variables.viewMode},
    });
  } else {
    result = await axiosConsumerAppInstance.get<DownloadLink>(
      `/files/download-link/${variables.accountId}/${variables.fileId}`,
      {params: {view_mode: variables.viewMode}}
    );
  }
  return result.data;
};

export const downloadUserFiles = async (variables: Variables): Promise<void | string> => {
  const viewMode = getViewMode(variables.fileName, variables.viewMode);
  variables.viewMode = viewMode;
  const {url, file_name} = await getUserFile(variables);
  if (variables.htmlView) {
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "text/html",
      },
    });
    return response.data;
  }
  const link = document.createElement("a");
  link.href = url;
  link.download = file_name;
  if (viewMode) {
    link.target = "_blank";
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFilesAsZip = async ({
  files,
  accountPublicId,
}: {
  files: DownloadLink[];
  accountPublicId: string;
}) => {
  const zip = new JSZip();
  try {
    for (const file of files) {
      const response = await fetch(file.url);
      if (response.ok) {
        const fileBlob = await response.blob();
        zip.file(file.file_name, fileBlob);
      }
    }
    const zipBlob = await zip.generateAsync({type: "blob"});
    const link = document.createElement("a");
    link.href = URL.createObjectURL(zipBlob);
    link.download = `Docs-${accountPublicId}.zip`;
    link.click();
    URL.revokeObjectURL(link.href);
  } catch (error) {}
};

const getViewMode = (fileName?: string, viewMode?: boolean): boolean => {
  if (!viewMode || !fileName) {
    return false;
  }
  const isSafari = isNotChromeOniPhone();

  return (
    !isSafari &&
    ["pdf", "html", "png", "jpeg", "jpg", "svg", "svg+xml", "tif", "tiff", "mp3"].some((fileType) =>
      fileName?.endsWith(fileType)
    )
  );
};
