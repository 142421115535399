import {useEffect, useState} from "react";
import {AxiosError} from "axios";

export const useFetchRequest = <TData>(fetchFunction: (variables: any) => Promise<TData>, variables: any) => {
  const [data, setData] = useState<TData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<AxiosError | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setError(null);

      try {
        const result = await fetchFunction(variables);
        setData(result);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return {data, isLoading, error};
};
