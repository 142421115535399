import {axiosConsumerAppInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {GuestPaymentDetailsResponse} from "../../../declerations/server/payment_plan_models";

type Variables = {
  token: string;
};

type Data = GuestPaymentDetailsResponse;

export const getGuestPaymentDetails = async (variables: Variables): Promise<Data> => {
  const result = await axiosConsumerAppInstance.get<Data, AxiosResponse<Data>>(`/payments/guest/${variables.token}`);
  return result.data;
};
