import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";
import NiceModal, {NiceModalHocProps, useModal} from "@ebay/nice-modal-react";
import {PaymentType} from "../../../../declerations/enums";
import {PaymentDetails} from "../../../../declerations/server";
import {useSinglePayment, useChangePaymentMethod} from "../../../../apis/requests/payments";
import {useUserMetaData, useUtils} from "../../../../contexts";
import {openPaymentFailedDialog, PaymentContext} from "../../../../pages/account/payment/utils";
import {CustomDialog, Text} from "bounce-ui/consumer";
import PaymentSuccessDialog from "../../../../pages/account/account-dashboard/component-v2/dialogs/PaymentSuccessDialog";
import {useGetDashboardQuery} from "../../../../apis/requests/plans";
import PaymentMethod from "./PaymentMethod";
import CustomPaymentBox from "../../../../pages/account/payment/components/CustomPaymentBox";
import {Pages} from "../../../../bi-events/enum";
import currency from "currency.js";
import {isUndefined} from "lodash";

export type IOneTimePaymentDialog = {
  planBalance: number;
  accountId: string;
  activePaymentPlanId?: string;
};

const OneTimePaymentDialog: FunctionComponent<IOneTimePaymentDialog & NiceModalHocProps> = NiceModal.create(
  ({accountId, planBalance, activePaymentPlanId}) => {
    const {pageViewEvent, pageClickEvent, enqueueSnackbar} = useUtils();
    const {isAgent, getBoUserId} = useUserMetaData();
    const modal = useModal();
    const userId = getBoUserId();
    const [buttonTouched, setButtonTouched] = useState(false);
    const [amount, setAmount] = useState<number>();

    const isValid = useMemo<boolean>(() => {
      if (amount !== undefined) {
        return amount <= planBalance && amount > 1;
      }
      return !!planBalance;
    }, [amount, planBalance]);

    const userDashboard = useGetDashboardQuery(
      {
        account_id: accountId,
        isAgent,
        payment_plan_id: activePaymentPlanId,
      },
      {enabled: !!activePaymentPlanId}
    );
    const cardDetails = userDashboard.data?.last_4_digits;

    const closeHandler = useCallback((): void => {
      modal.resolve(false);
      modal.hide();
    }, []);

    const paymentState = useSinglePayment();
    const changePaymentMethod = useChangePaymentMethod({refetchType: "active"});
    const changePaymentMethodHandler = useCallback(
      async (paymentType: PaymentType, paymentDetails: PaymentDetails): Promise<void> => {
        try {
          const result = await changePaymentMethod.mutateAsync({
            ...paymentDetails,
            account_id: accountId,
            payment_plan_id: userDashboard.data?.payment_plan_id,
            isAgent,
            user_id: getBoUserId(),
          });
          if (result.success) {
            enqueueSnackbar(<Text size={14}>Changes to your payment method were saved</Text>, {variant: "success"});
          } else {
            openPaymentFailedDialog(result.error_message || "Payment failed");
            setButtonTouched(false);
          }
        } catch (e) {
          enqueueSnackbar(<Text size={14}>Failed to change your payment method</Text>, {variant: "error"});
        }
      },
      [accountId, changePaymentMethod, userDashboard.data]
    );

    const paymentHandler = useCallback(
      async (
        paymentType: PaymentType,
        paymentDetails: PaymentDetails,
        isChangePaymentMethod: boolean
      ): Promise<void> => {
        let error;
        try {
          const result = await paymentState.mutateAsync({
            ...paymentDetails,
            account_id: accountId,
            user_id: userId,
            amount: `${amount}`,
            isAgent,
          });
          if (result.success) {
            if (isChangePaymentMethod) {
              changePaymentMethodHandler(paymentType, paymentDetails);
            }
            paymentSuccessHandler();
          } else {
            error = result.error_message || "Payment failed";
            openPaymentFailedDialog(error);
            setButtonTouched(false);
          }
        } catch (e) {
          paymentFailedHandler();
        } finally {
          pageClickEvent({
            page: Pages.ONE_TIME_PAYMENT_DIALOG,
            cta: "pay_it_now",
            data: {
              contact: true,
              amount: currency(amount || 0).divide(100).value,
              payment_method: paymentType,
              error,
            },
          });
        }
      },
      [accountId, paymentState]
    );

    const paymentSuccessHandler = useCallback(async (): Promise<void> => {
      closeHandler();
      await NiceModal.show<boolean>(PaymentSuccessDialog, {});
    }, []);

    const paymentFailedHandler = useCallback((): void => {
      setButtonTouched(false);
    }, []);

    useEffect(() => {
      pageViewEvent({page: Pages.ONE_TIME_PAYMENT_DIALOG});
    }, []);

    return (
      <PaymentContext.Provider
        value={{
          buttonTouched,
          setButtonTouched,
          isLoading: paymentState.isLoading,
        }}
      >
        <CustomDialog title={"One-time payment"}>
          <CustomPaymentBox
            amount={amount}
            isValid={isValid}
            totalBalance={planBalance}
            setAmount={setAmount}
            isDialog
          />
          <PaymentMethod
            accountId={accountId}
            paymentHandler={paymentHandler}
            lastDigitsCard={cardDetails}
            disable={isUndefined(amount) || !isValid}
            currentMethodType={userDashboard.data?.payment_method_type}
            page={Pages.ONE_TIME_PAYMENT_DIALOG}
            paymentPlanId={activePaymentPlanId}
          />
        </CustomDialog>
      </PaymentContext.Provider>
    );
  }
);

export default OneTimePaymentDialog;
