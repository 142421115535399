import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";
import {Container, Text, Dialog} from "bounce-ui/consumer";
import ContactMethod from "../components/ContactMethod";
import {ContactMethodType} from "../../../../declerations/enums";
import NiceModal, {NiceModalHocProps, useModal} from "@ebay/nice-modal-react";
import {useLocation, useNavigate} from "react-router-dom";
import {urls} from "../../../../routes/urls";
import {useGetCommunicationPreferencesQuery} from "../../../../apis/requests/user/getCommunicationPreferences";
import LoadingCenter from "../../../../components/common/LoadingCenter";
import {UserCommunicationPreferences} from "../../../../declerations/server";
import {useUpdateCommunicationPreferences} from "../../../../apis/requests/user/updateCommunicationPreferences";
import {merge} from "lodash";
import {useAmplitude, useUi, useUser} from "../../../../contexts";
import {useAddContactToUser} from "../../../../apis/requests/user/addContactDetail";
import {getIpAddress} from "../utils";
import FormValidations from "../../../../helpers/FormValidations";
import {Pages} from "../../../../bi-events/enum";
import EditContactDialog from "pages/backoffice/information-panel/tabs/account-tab/sections/EditContactDialog";

const appendConsent = (sms: boolean, email: boolean, phone_call: boolean) => {
  const consent: string[] = [];
  if (sms) consent.push("sms");
  if (email) consent.push("email");
  if (phone_call) consent.push("phone_call");
  return consent;
};

const ContactMethodsDialog: FunctionComponent<NiceModalHocProps> = NiceModal.create(() => {
  const {pageViewEvent, pageClickEvent} = useAmplitude();
  const {setApprovedContactMethods} = useUser();
  const {uiSettings} = useUi();
  const navigate = useNavigate();
  const {state} = useLocation();
  const modal = useModal();

  const closeHandler = (): void => {
    modal.resolve(true);
    modal.hide();
  };
  const [communicationPreferences, setCommunicationPreferences] = useState<UserCommunicationPreferences | undefined>(
    undefined
  );
  const [userIp, setUserIp] = useState("");

  const isValid = useMemo<boolean>(
    () =>
      !!communicationPreferences?.SMS?.allowed ||
      !!communicationPreferences?.email?.allowed ||
      !!communicationPreferences?.phone_call?.allowed,
    [communicationPreferences]
  );

  const getCommunicationPreferencesState = useGetCommunicationPreferencesQuery({});
  const updateCommunicationPreferencesState = useUpdateCommunicationPreferences();
  const useAddPhone = useAddContactToUser();
  const useAddEmail = useAddContactToUser();

  useEffect(() => {
    const result = getCommunicationPreferencesState.data;
    if (result) {
      setCommunicationPreferences(result);
    }
  }, [getCommunicationPreferencesState.data]);

  useEffect(() => {
    getIpAddress().then((ip) => setUserIp(ip));
  }, [setUserIp]);

  const smsChecked = useMemo<boolean>(() => !!communicationPreferences?.SMS?.allowed, [communicationPreferences]);
  const emailChecked = useMemo<boolean>(() => !!communicationPreferences?.email?.allowed, [communicationPreferences]);
  const phoneCallChecked = useMemo<boolean>(
    () => !!communicationPreferences?.phone_call?.allowed,
    [communicationPreferences]
  );

  const smsValue = useMemo<string>(() => communicationPreferences?.SMS?.detail || "", [communicationPreferences]);
  const emailValue = useMemo<string>(() => communicationPreferences?.email?.detail || "", [communicationPreferences]);
  const phoneCallValue = useMemo<string>(
    () => communicationPreferences?.phone_call?.detail || "",
    [communicationPreferences]
  );

  const submitHandler = useCallback(async (): Promise<void> => {
    try {
      const result = await updateCommunicationPreferencesState.mutateAsync({
        ...communicationPreferences!,
      });
      if (result) {
        const consent = appendConsent(smsChecked, emailChecked, phoneCallChecked);
        pageClickEvent({page: Pages.MANAGE_CONTACT, cta: "approve", data: {consent}});
        setApprovedContactMethods(true);
        closeHandler();
        if (state?.origin) {
          navigate(state.origin);
        } else {
          navigate(urls.accounts.url);
        }
      }
    } catch (e) {}
  }, [communicationPreferences, isValid, userIp]);

  const contactMethodCheckedHandler = useCallback(
    (type: ContactMethodType, checked: boolean): void => {
      setCommunicationPreferences((preferences) =>
        preferences ? merge({}, preferences, {[type]: {allowed: checked}}) : undefined
      );
    },
    [setCommunicationPreferences]
  );

  const editValueHandler = useCallback(
    (type: ContactMethodType, value: string): void => {
      switch (type) {
        case "SMS":
        case "phone_call":
          pageClickEvent({page: Pages.MANAGE_CONTACT, cta: "edit_phone"});
          NiceModal.show(EditContactDialog, {
            type: "tel",
            value: value,
            isLoading: useAddPhone.isLoading,
            onValueUpdated: (value: string) => {
              try {
                useAddPhone.mutate({
                  contact: {
                    status: "VALID",
                    phone: value,
                    is_primary: true,
                  },
                  user_id: state.userId,
                  contactType: "phone",
                });
                if (useAddPhone.isSuccess) {
                  setCommunicationPreferences((preferences) =>
                    preferences ? merge({}, preferences, {[type]: {detail: value}}) : undefined
                  );
                }
              } catch (e) {}
            },
          });
          break;
        case "email":
          pageClickEvent({page: Pages.MANAGE_CONTACT, cta: "edit_email"});
          NiceModal.show(EditContactDialog, {
            type: "email",
            value: value,
            isLoading: useAddPhone.isLoading,
            onValueUpdated: (value: string) => {
              try {
                useAddEmail.mutate({
                  contact: {email: value, is_primary: true},
                  user_id: state.userId,
                  contactType: "email",
                });
                if (useAddEmail.isSuccess) {
                  setCommunicationPreferences((preferences) =>
                    preferences ? merge({}, preferences, {[type]: {detail: value}}) : undefined
                  );
                }
              } catch (e) {}
            },
          });
          break;
      }
    },
    [setCommunicationPreferences, phoneCallChecked, smsChecked]
  );

  useEffect(() => {
    pageViewEvent({page: Pages.MANAGE_CONTACT});
  }, []);

  return (
    <Dialog title={"What is your preferred contact method?"} buttonText={"Approve"} onClick={submitHandler}>
      <Container fullWidth sx={{overflow: "auto", gap: "8px", height: "200px"}}>
        {!communicationPreferences ? (
          <LoadingCenter />
        ) : (
          <>
            <ContactMethod
              type={"SMS"}
              title={"By SMS"}
              value={FormValidations.convertInternationalIntoUSFormat(smsValue) || ""}
              checked={smsChecked}
              onCheckedChange={contactMethodCheckedHandler}
              onEditValue={editValueHandler}
            />
            <ContactMethod
              type={"email"}
              title={"By Email"}
              value={emailValue}
              checked={emailChecked}
              onCheckedChange={contactMethodCheckedHandler}
              onEditValue={editValueHandler}
            />
            <ContactMethod
              type={"phone_call"}
              title={"By Phone Call"}
              value={FormValidations.convertInternationalIntoUSFormat(phoneCallValue) || ""}
              checked={phoneCallChecked}
              onCheckedChange={contactMethodCheckedHandler}
              onEditValue={editValueHandler}
            />
          </>
        )}
      </Container>
      <Text size={12}>
        {`By approving, you consent to being contacted by ${uiSettings?.collection_label} via the selected communication channels
        regarding your account.`}
      </Text>
    </Dialog>
  );
});

export default ContactMethodsDialog;
