import {useMemo, ReactNode} from "react";
import {CssBaseline} from "@mui/material";
import {ThemeProvider, ThemeOptions, createTheme} from "@mui/material/styles";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import shape from "./shape";
import palette from "./palette";
import typography from "./typography";
import breakpoints from "./breakpoints";
import componentsOverride from "./overrides";
import shadows, {customShadows} from "./shadows";
import {enUS, Localization} from "@mui/material/locale";
import type {} from "@mui/lab/themeAugmentation";
import ChartStyle from "../components/charts/ChartStyle";

type ThemeConfigProps = {
  localization?: Localization;
  children: ReactNode;
};
export const themeOptions = () => {
  const theme: ThemeOptions = {
    palette: {
      ...palette.light,
      mode: "light",
    },
    shape,
    typography,
    breakpoints,
    direction: "ltr",
    shadows: shadows.light,
    customShadows: customShadows.light,
  };

  return theme;
};

export default function ThemeConfig({localization, children}: ThemeConfigProps) {
  const themeOptionsMemoized: ThemeOptions = useMemo(() => themeOptions(), []);
  const theme = createTheme(themeOptionsMemoized, localization || enUS);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ChartStyle />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
