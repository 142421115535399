import {FunctionComponent, useEffect, useMemo, useState} from "react";
import {useTitle} from "react-use";
import {getUrlInfo} from "../routes/urls";
import {useUtils} from "../contexts";

interface TitleManagerProps {}

const TitleManager: FunctionComponent<TitleManagerProps> = () => {
  const {uiSettings, pathname} = useUtils();

  const [title, setTitle] = useState<string>("");
  useTitle(title);

  const name = useMemo<string>(() => uiSettings?.lender_name || "", [uiSettings]);

  useEffect(() => {
    if (!pathname) {
      setTitle(name);
      return;
    }

    const urlInfo = getUrlInfo(pathname);
    if (!urlInfo) {
      setTitle(name);
      return;
    }
    if (pathname?.includes("backoffice")) {
      setTitle(`Backoffice | ${urlInfo.titleKey}`);
    } else {
      setTitle(`${urlInfo.titleKey} | ${name}`);
    }
  }, [pathname, name]);

  return null;
};
export default TitleManager;
