import {Outlet} from "react-router-dom";
import {experimentalStyled as styled} from "@mui/material/styles";
import AccountHeader from "./AccountHeader";
import {NAVBAR_DESKTOP_HEIGHT, NAVBAR_MOBILE_HEIGHT} from "../../constants/ui";
import AccountFooter from "./AccountFooter";
import {FunctionComponent} from "react";
import {useUi, useUser} from "../../contexts";
import {Colors, LottieBounce} from "../../bounce-ui/consumer";

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
  flexDirection: "column",
  backgroundColor: Colors.tertiary._100,
});

const MainStyle = styled("div")(({theme}) => ({
  flexGrow: 1,
  overflow: "auto",
  height: "570px",
  paddingTop: NAVBAR_MOBILE_HEIGHT,
  [theme.breakpoints.up("lg")]: {
    paddingTop: NAVBAR_DESKTOP_HEIGHT,
  },
}));

type AccountLayoutProps = {};

const AccountLoginLayout: FunctionComponent<AccountLayoutProps> = () => {
  const {isAgent} = useUser();
  const {showSplashScreen, setShowSplashScreen} = useUi();
  return (
    <RootStyle>
      {!isAgent && <AccountHeader />}
      {showSplashScreen ? (
        <LottieBounce handleComplete={() => setShowSplashScreen(false)} />
      ) : (
        <>
          <MainStyle>
            <Outlet />
          </MainStyle>
          {!isAgent && <AccountFooter />}
        </>
      )}
    </RootStyle>
  );
};

export default AccountLoginLayout;
