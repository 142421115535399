import React, {FunctionComponent, useCallback, useContext, useMemo} from "react";
import {FormControl, FormHelperText, MenuItem} from "@mui/material";
import {
  Container,
  Text,
  LoadingButton,
  TextField,
  Link,
  Checkbox,
  FormControlLabel,
} from "../../../../../bounce-ui/consumer";
import {Controller, useForm} from "react-hook-form";
import {EMAIL_REGEX} from "../../../../../constants/regex";
import {FormattedMessage} from "react-intl";
import {bankAccountTypeInfoMap} from "../../../../../constants/enums";
import {SelectOption} from "../../../../../declerations/internal";
import InputUtils from "../../../../../helpers/InputUtils";
import {BankAccountType, PaymentType} from "../../../../../declerations/enums";
import {PaymentDetails} from "../../../../../declerations/server";
import NiceModal from "@ebay/nice-modal-react";
import TermsAndConditionsDialog from "../TermsAndConditionsDialog";
import {PaymentContext} from "../../utils";
import {PaymentsImages} from "../PaymentTabPanel";
import LargePaymentSplitLabel from "../LargePaymentsSplitLabel";
import {Pages} from "../../../../../bi-events/enum";
import {useUtils} from "../../../../../contexts";

interface AchPaymentFormProps {
  onAchSubmit: (paymentType: PaymentType, paymentDetails: PaymentDetails, isChangePaymentMethod: boolean) => void;
  submitLabelId?: string;
  termsAndConditionsId?: string;
  termsAndConditionsTextId?: string;
  isChangePaymentMethod: boolean;
  disable?: boolean;
  primaryEmail?: string;
  userName?: string;
}

type FormValues = {
  accountType: BankAccountType;
  name: string;
  accountNumber: string;
  routingNumber: string;
  email: string;
  confirmTerms: boolean;
};

const AchPaymentFormTest: FunctionComponent<AchPaymentFormProps> = ({
  onAchSubmit,
  submitLabelId,
  termsAndConditionsId,
  termsAndConditionsTextId,
  isChangePaymentMethod,
  primaryEmail,
  userName,
}: AchPaymentFormProps) => {
  const {pageClickEvent, uiSettings} = useUtils();
  const {disableACHAccountOwnerPrePopulation} = useContext(PaymentContext);
  const {
    handleSubmit,
    control,
    formState: {isValid},
  } = useForm<FormValues>({
    defaultValues: {name: disableACHAccountOwnerPrePopulation ? "" : userName},
  });
  const {buttonTouched, setButtonTouched, isLoading, disableButton, showLargeSplitLabel} = useContext(PaymentContext);

  const onSubmit = handleSubmit(async (data): Promise<void> => {
    const paymentDetails = {
      ach: {
        account_type: data.accountType,
        account_name: data.name,
        account_number: InputUtils.removeWhitespaces(data.accountNumber),
        ABA: InputUtils.removeWhitespaces(data.routingNumber),
        state: "",
        zip_code: "",
      },
      email: data.email || undefined,
    };
    setButtonTouched(true);
    onAchSubmit("ACH", paymentDetails, isChangePaymentMethod);
  });

  const termsAndConditionsHandler = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    pageClickEvent({page: Pages.PAYMENT_SUMMARY, cta: "t_c"});
    event.preventDefault();
    NiceModal.show(TermsAndConditionsDialog, {termsAndConditionsTextId});
  }, []);

  const bankAccountTypes = useMemo<SelectOption<BankAccountType>[]>(
    () => InputUtils.getEnumSelectOptions(bankAccountTypeInfoMap),
    []
  );

  return (
    <form autoComplete="off" noValidate onSubmit={onSubmit}>
      <Container sx={{gap: "20px"}}>
        {showLargeSplitLabel && <LargePaymentSplitLabel />}
        <Controller
          name="accountNumber"
          rules={{
            required: "Required field",
          }}
          control={control}
          defaultValue=""
          render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
            return (
              <TextField
                {...field}
                inputRef={ref}
                fullWidth
                placeholder={"Your account number (9-12 digits)"}
                label={"Bank account number"}
                type="text"
                error={invalid}
                helperText={error?.message}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 20,
                }}
              />
            );
          }}
        />
        <Controller
          name="routingNumber"
          rules={{
            required: "Required field",
          }}
          control={control}
          defaultValue=""
          render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
            return (
              <TextField
                {...field}
                inputRef={ref}
                fullWidth
                label={"Bank routing number"}
                placeholder={"XXX-XXX-XXX"}
                type="text"
                error={invalid}
                helperText={error?.message}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 30,
                }}
              />
            );
          }}
        />
        <Controller
          name="name"
          rules={{
            required: "Required field",
          }}
          control={control}
          defaultValue=""
          render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
            return (
              <TextField
                {...field}
                inputRef={ref}
                fullWidth
                label={"Account owner's name"}
                type="text"
                error={invalid}
                helperText={error?.message}
                inputProps={{
                  maxLength: 50,
                }}
              />
            );
          }}
        />
        <Controller
          name="accountType"
          rules={{
            required: "Required field",
          }}
          control={control}
          defaultValue={bankAccountTypes[0].value}
          render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
            return (
              <TextField
                {...field}
                inputRef={ref}
                fullWidth
                label={"Bank account type"}
                error={invalid}
                helperText={error?.message}
                select
              >
                {bankAccountTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <FormattedMessage id={option.label} />
                  </MenuItem>
                ))}
              </TextField>
            );
          }}
        />
        <Controller
          name="email"
          rules={{
            pattern: {value: EMAIL_REGEX, message: "Email invalid"},
          }}
          control={control}
          defaultValue={primaryEmail}
          render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
            return (
              <TextField
                {...field}
                inputRef={ref}
                fullWidth
                label={"Email address for receipts"}
                type="email"
                error={invalid}
                helperText={error?.message}
                inputProps={{
                  maxLength: 50,
                }}
              />
            );
          }}
        />
        <PaymentsImages />
        <Controller
          name="confirmTerms"
          rules={{
            required: "Required field",
          }}
          control={control}
          defaultValue={false}
          render={({field: {ref, value, ...field}, fieldState: {invalid, error}}) => {
            return (
              <FormControl required error={invalid}>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      inputRef={ref}
                      size={"small"}
                      checked={value}
                      sx={{...(termsAndConditionsId !== "singlePaymentTermsAndConditions" && {paddingTop: "4px"})}}
                    />
                  }
                  label={
                    <Text
                      translateId={termsAndConditionsId || "paymentTermsAndConditions"}
                      size={12}
                      values={{
                        termsAndConditions: (
                          <Link href={"#"} onClick={termsAndConditionsHandler}>
                            <Text
                              size={12}
                              translateId={"termsAndConditions"}
                              fontWeight={"bold"}
                              color={"secondary"}
                              sx={{display: "inline"}}
                            />
                          </Link>
                        ),
                        collectionLabel: uiSettings?.collection_label || "",
                      }}
                    />
                  }
                  sx={{alignItems: "start"}}
                />
                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            );
          }}
        />
        <LoadingButton
          id={submitLabelId || "Confirm payment"}
          disabled={buttonTouched || disableButton || !isValid}
          loading={isLoading}
          type={"submit"}
        />
      </Container>
    </form>
  );
};
export default AchPaymentFormTest;
