import dayjs, {Dayjs} from "dayjs";
import {DATE_FORMAT_V2} from "../../backoffice/information-panel/components/dialogs/common";
import {PaymentPlanOffer, PaymentPlanOffers} from "../../../declerations/server/payment_plan_models";
import {formatCurrency} from "../../../helpers/formatNumber";
import {experimentalStyled as styled} from "@mui/material/styles";
import {ToggleButton} from "@mui/material";
import businessDays from "business-days-js";
import {Colors, FormControlLabel} from "../../../bounce-ui/consumer";
const bDays = businessDays();

export enum PLAN_STEPS {
  FREQUENCY = "frequency",
  AMOUNT = "amount",
  SUMMARY = "summary",
}

export interface IInterimLimits {
  max_installment_size_biweekly: number;
  max_installment_size_monthly: number;
  max_installment_size_weekly: number;
  max_months_plan_length: number;
  min_installment_size: number;
}

const planSteps = [PLAN_STEPS.FREQUENCY, PLAN_STEPS.AMOUNT, PLAN_STEPS.SUMMARY];

const mapFrequencyToTitle: Record<string, {title: string; days: number}> = {
  weekly: {title: "Weekly", days: 7},
  bi_weekly: {title: "Every 2 Weeks", days: 14},
  first_and_fifteenth: {title: "Every 1st & 15th", days: 15},
  monthly: {title: "Monthly", days: 30},
  one_time: {title: "One Time", days: 1},
  last_business_day_of_the_month: {title: "Last business day of the month", days: 30},
};
const mapFrequencyToSuggestionsLabels: Record<string, {label: string; shortLabel: string}> = {
  weekly: {label: "weeks", shortLabel: "wk"},
  bi_weekly: {label: "payments", shortLabel: " 2wk"},
  first_and_fifteenth: {label: "payments", shortLabel: " 1st & 15th"},
  monthly: {label: "months", shortLabel: "mo"},
};

const autoFocus = (input: any) => {
  if (input) {
    setTimeout(() => {
      input.focus();
    }, 100);
  }
};

const buildMarks = (planOffersByFrequency: PaymentPlanOffer[]) => {
  return planOffersByFrequency
    .filter((plan) => {
      return !plan.resolution_expert_only;
    })
    .map((plan, index) => {
      const isFirstOrLast = index === 0 || index === planOffersByFrequency.length - 1;
      return {
        label: isFirstOrLast && formatCurrency(plan.installment_size),
        value: index,
      };
    });
};

const formatDate = (date: Dayjs) => dayjs.tz(date, "America/New_York").utc(false).format(DATE_FORMAT_V2);
const StyledFormControlLabel = styled(FormControlLabel)<{selected: boolean}>(({theme, selected, disabled}) => ({
  padding: "8px 15px",
  width: "100%",
  minHeight: "63px",
  gap: "12px",
  fontWeight: "600px",
  border: "1px solid",
  borderRadius: "4px",
  borderColor: selected ? Colors.grey._300 : Colors.grey._200,
}));

const CustomToggleButton = styled(ToggleButton)(({theme}) => ({
  fontSize: "13px",
  fontWeight: "medium",
  margin: "5px",
  height: "35px",
  color: Colors.primary._800,
  border: "none",
  whiteSpace: "nowrap",
  borderRadius: "0px",
  "&.Mui-selected": {
    color: Colors.bw.white,
    backgroundColor: Colors.primary._800,
  },
  "&:not(.Mui-selected)": {
    backgroundColor: Colors.grey._100,
  },
}));

const nextFirstAndFifteenthDates = ({
  numberOfDays = 30,
  startDate = dayjs(),
}: {
  numberOfDays?: number;
  startDate?: dayjs.Dayjs;
}): dayjs.Dayjs[] => {
  const nextDays: dayjs.Dayjs[] = [];
  [...Array(numberOfDays)].forEach((_, i) => {
    const currentDate = dayjs(startDate).add(i, "day");
    const dayOfMonth = currentDate.date();

    if (dayOfMonth === 1 || dayOfMonth === 15) {
      nextDays.push(currentDate.clone());
    }
  });

  return nextDays;
};

const nextLastBusinessDayOfTheMonthDates = ({
  numberOfMonths = 2,
  startDate = dayjs(),
}: {
  numberOfMonths?: number;
  startDate?: dayjs.Dayjs;
}): dayjs.Dayjs[] => {
  const nextDays: dayjs.Dayjs[] = [];
  [...Array(numberOfMonths)].forEach((_, i) => {
    let lastBusinessDay = dayjs(startDate.add(i, "month")).endOf("month");
    while (!bDays.check(lastBusinessDay.toDate())) {
      lastBusinessDay = lastBusinessDay.subtract(1, "day");
    }
    nextDays.push(lastBusinessDay.clone());
  });

  return nextDays;
};

const getFirstFrequencyWithOptions = (planOffers: PaymentPlanOffers) => {
  if (planOffers) {
    if (planOffers.weekly.length > 0) return "weekly";
    if (planOffers.bi_weekly.length > 0) return "bi_weekly";
    if (planOffers.first_and_fifteenth.length > 0) return "first_and_fifteenth";
    if (planOffers.monthly.length > 0) return "monthly";
  }
  return null;
};

const sortPlansByInstallment = (paymentOffers: PaymentPlanOffer[]): PaymentPlanOffer[] => {
  return paymentOffers.sort((a, b) => {
    if (b.hardship_payment_plan && a.hardship_payment_plan) {
      return a.hardship_payment_plan?.installment_size - b.hardship_payment_plan?.installment_size;
    }
    return 0;
  });
};

export {
  planSteps,
  mapFrequencyToTitle,
  mapFrequencyToSuggestionsLabels,
  autoFocus,
  buildMarks,
  formatDate,
  StyledFormControlLabel,
  CustomToggleButton,
  nextFirstAndFifteenthDates,
  nextLastBusinessDayOfTheMonthDates,
  getFirstFrequencyWithOptions,
  sortPlansByInstallment,
};
