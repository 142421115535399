import React from "react";
import {Checkbox as MuiCheckbox, CheckboxProps} from "@mui/material";
import {Colors} from "../colors";
import {IconCheckboxEmpty, IconCheckboxNeutral, IconCheckboxPrimary, IconCheckboxSecondary} from "../assets";

export type CheckboxType = CheckboxProps & {color?: "primary" | "secondary" | "error"};

export const mapCheckedIcon = {
  primary: IconCheckboxPrimary,
  secondary: IconCheckboxSecondary,
  error: IconCheckboxNeutral,
};

const Size = {
  small: "16px",
  medium: "20px",
  large: "24px",
};

export const Checkbox = ({color = "primary", size = "small", sx, ...props}: CheckboxType) => {
  const Icon = mapCheckedIcon[color];
  return (
    <MuiCheckbox
      icon={<IconCheckboxEmpty width={Size[size]} height={Size[size]} />}
      checkedIcon={<Icon width={Size[size]} height={Size[size]} />}
      sx={{
        "&.Mui-checked": {
          ...(color === "secondary" && {color: Colors.grey._1000}),
        },
        margin: "2px 6px",
        padding: "0px",
        ...sx,
      }}
      {...props}
    />
  );
};
