import React from "react";
import {PaymentType} from "../../../../../declerations/enums";
import {PaymentDetails} from "../../../../../declerations/server";
import {Colors, Chip} from "../../../../../bounce-ui/consumer";
import PaymentFormWrapper from "./PaymentFormWrapper";
import {Segmented} from "antd";
import {Label} from "../../../account/PlanPicker/utils";
import AchPaymentFormTest from "./AchPaymentFormTest";
import DebitPaymentFormTest from "./DebitPaymentFormTest";

interface IPaymentTabsTest {
  paymentMethod: PaymentType;
  setPaymentMethod: (value: PaymentType) => void;
  onCardSubmit: (paymentType: PaymentType, paymentDetails: PaymentDetails, isChangePaymentMethod: boolean) => void;
  submitLabelId?: string;
  termsAndConditionsId?: string;
  termsAndConditionsTextId?: string;
  isDashboardDialog: boolean;
  primaryEmail?: string;
  userName?: string;
  onAchSubmit: (paymentType: PaymentType, paymentDetails: PaymentDetails, isChangePaymentMethod: boolean) => void;
}

const PaymentTabsTest = ({
  paymentMethod,
  setPaymentMethod,
  onCardSubmit,
  submitLabelId,
  termsAndConditionsId,
  termsAndConditionsTextId,
  isDashboardDialog,
  primaryEmail,
  userName,
  onAchSubmit,
}: IPaymentTabsTest) => {
  return (
    <PaymentFormWrapper isDashboardDialog={isDashboardDialog}>
      <Segmented
        defaultValue={paymentMethod}
        onChange={() => setPaymentMethod(paymentMethod === "ACH" ? "DebitCard" : "ACH")}
        size={"large"}
        style={{padding: "4px", width: "100%", backgroundColor: Colors.tertiary._200}}
        options={[
          {
            label: (
              <Label label={"ACH"}>
                <Chip label={"Popular"} variant_={"primary"} />
              </Label>
            ),
            value: "ACH",
          },
          {
            label: <Label label={"Debit card"} />,
            value: "DebitCard",
          },
        ]}
        block
      />
      {paymentMethod === "ACH" && (
        <AchPaymentFormTest
          onAchSubmit={onAchSubmit}
          submitLabelId={submitLabelId}
          termsAndConditionsId={termsAndConditionsId}
          termsAndConditionsTextId={termsAndConditionsTextId}
          isChangePaymentMethod={isDashboardDialog}
          primaryEmail={primaryEmail}
          userName={userName || ""}
        />
      )}
      {paymentMethod === "DebitCard" && (
        <DebitPaymentFormTest
          type={"DebitCard"}
          onCardSubmit={onCardSubmit}
          submitLabelId={submitLabelId}
          termsAndConditionsId={termsAndConditionsId}
          termsAndConditionsTextId={termsAndConditionsTextId}
          isChangePaymentMethod={isDashboardDialog}
          primaryEmail={primaryEmail}
          userName={userName || ""}
        />
      )}
    </PaymentFormWrapper>
  );
};
export default PaymentTabsTest;
