import React from "react";
import {LinearProgress, IconButton} from "@mui/material";
import {IconBackArrow} from "../../assets";
import {Container} from "../Container";
import {Text} from "../Text";
import {IStepperProps} from "./Stepper";
import {Colors} from "../../colors";

export const ProgressStepper = ({numberOfSteps, activeStep, handleBack, startOnFirstStep}: IStepperProps) => {
  const activeStepValue = startOnFirstStep ? activeStep + 1 : activeStep;
  return (
    <Container fullWidth row sx={{gap: "20px"}}>
      <IconButton onClick={handleBack} sx={{padding: "0px"}}>
        <IconBackArrow />
      </IconButton>
      <LinearProgress
        variant="determinate"
        value={(activeStep / numberOfSteps) * 100}
        sx={{
          height: "4px",
          borderRadius: "2px",
          width: "100%",
          "& .MuiLinearProgress-bar": {
            background: "linear-gradient(90deg, #6EEDAF 0%, #03542C 100%)",
          },
          backgroundColor: Colors.tertiary._300,
        }}
      />
      <Text variant={"body3"} color={"secondary"} size={14}>{`0${activeStepValue}/0${numberOfSteps}`}</Text>
    </Container>
  );
};
